<template>
  <div class="container-fluid px-0 min-h w-100 text-roboto">
    <mdb-navbar color="elegant" dark class="navnav w-100">
      <mdb-navbar-toggler>
        <mdb-navbar-nav left>
          <mdb-dropdown tag="li" class="nav-item">
            <mdb-dropdown-toggle tag="a" navLink slot="toggle" waves-fixed
              >Таблицы</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu>
              <mdb-dropdown-item @click="openDeliveryTable" active
                >Лист отправки</mdb-dropdown-item
              >
              <mdb-dropdown-item @click="openToolsTable"
                >План</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-navbar-nav>
        <mdb-navbar-nav right>
          <mdb-nav-item @click="logout">Выход</mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>

    <div class="row mx-0 mb-4 animated fadeInLeft">
      <table class="white mb-3 px-0 text-left w-100 mw-100 min-h">
        <tr class="text-center">
          <th class="th_date border-dark border">№</th>
          <th class="border-dark border">
            <p
              class="mb-0 zoom_item"
              style="cursor: pointer"
              @click="modal_date = true"
            >
              {{ today }}
            </p>

            <hr class="my-1" />
            Почта
          </th>
          <th class="border border-dark">Допы</th>
          <th class="px-2 border border-dark">СДЭК <br />Санкт-Петербург</th>
          <th class="border border-dark">Допы</th>
          <th :class="{ hidden: !test2 }" class="px-2 border border-dark">
            СДЭК <br />Санкт-Петербург
          </th>
          <th class="border-dark border" :class="{ hidden: !test2 }">Допы</th>
          <th class="border-dark border" :class="{ hidden: SamovuvozOfficeH }">
            Самовывоз <br />из офиса
          </th>
          <th class="border-dark border" :class="{ hidden: SamovuvozOfficeH }">
            Допы
          </th>
          <th class="border-dark border px-3" :class="{ hidden: ZaberiMenyaH }">
            Забери Товар
          </th>
          <th class="border-dark border" :class="{ hidden: ZaberiMenyaH }">
            Допы
          </th>
          <th class="border-dark border" :class="{ hidden: DostavistaSpbH }">
            Достависта Санкт-Петербург
          </th>
          <th class="border-dark border" :class="{ hidden: DostavistaSpbH }">
            Допы
          </th>
          <th class="border-dark border" :class="{ hidden: DostavistaMskH }">
            Достависта Москва
          </th>
          <th class="border-dark border" :class="{ hidden: DostavistaMskH }">
            Допы
          </th>
          <th class="border-dark border" :class="{ hidden: SamovuvozMskH }">
            Самовывоз МСК (партнеры)
          </th>
          <th class="border-dark border" :class="{ hidden: SamovuvozMskH }">
            Допы
          </th>
        </tr>

        <tbody class="border border-dark rounded-bottom">
          <tr
            v-for="(order, index) in renderOrdersInStr"
            :key="index"
            class="trT text-table border border-dark"
          >
            <td class="text-left td_index border border-dark">
              {{ index + 1 }}
            </td>

            <td class="border border-dark">
              {{ order.Post_Id }}
            </td>
            <td class="text-right" v-html="order.Post_Basket"></td>

            <td class="border border-dark">
              {{ order.Sdek_Id }}
            </td>
            <td
              class="border border-dark text-right"
              v-html="order.Sdek_Basket"
            ></td>
            <td
              class="border border-dark border-right-0"
              :class="{ hidden: !test2 }"
            >
              {{ order.Sdek2_Id }}
            </td>
            <td
              class="border border-dark text-right"
              :class="{ hidden: !test2 }"
              v-html="order.Sdek2_Basket"
            ></td>

            <td
              class="border border-dark"
              :class="{ hidden: SamovuvozOfficeH }"
            >
              {{ order.SamovuvozOffice_Id }}
            </td>
            <td
              class="border border-dark text-right"
              :class="{ hidden: SamovuvozOfficeH }"
              v-html="order.SamovuvozOffice_Basket"
            ></td>
            <td class="border border-dark" :class="{ hidden: ZaberiMenyaH }">
              {{ order.ZaberiMenya_Id }}
            </td>
            <td
              class="border border-dark text-right"
              :class="{ hidden: ZaberiMenyaH }"
              v-html="order.ZaberiMenya_Basket"
            ></td>
            <td class="border border-dark" :class="{ hidden: DostavistaSpbH }">
              {{ order.DostavistaSpb_Id }}
            </td>
            <td
              class="border border-dark text-right"
              :class="{ hidden: DostavistaSpbH }"
              v-html="order.DostavistaSpb_Basket"
            ></td>
            <td class="border border-dark" :class="{ hidden: DostavistaMskH }">
              {{ order.DostavistaMsk_Id }}
            </td>
            <td
              class="border border-dark text-right"
              :class="{ hidden: DostavistaMskH }"
              v-html="order.DostavistaMsk_Basket"
            ></td>
            <td class="border border-dark" :class="{ hidden: SamovuvozMskH }">
              {{ order.SamovuvozMsk_Id }}
            </td>
            <td
              class="border border-dark text-right"
              :class="{ hidden: SamovuvozMskH }"
              v-html="order.SamovuvozMsk_Basket"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
    <mdb-modal
      :show="modal_date"
      @close="modal_date = false"
      class="modalmodal"
    >
      <mdb-modal-header>
        <mdb-modal-title>Дата отправки</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="my-3 text-center">
        <div class="row justify-content-around">
          <input
            v-model="datef"
            class="col-5 mx-1"
            type="date"
            min="2020-11-01"
          />
          <input
            v-model="datet"
            class="col-5 mx-1"
            type="date"
            min="2020-11-01"
          />
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <div class="row fixed-bottom mx-0 w-100 animated fadeInUp">
      <div class="white justify-content-start pt-2 sumClA pb-2 w-100">
        <div class="col">
          <span class="pl-2">Заказов: {{ orders_Sum }}</span>
          <span class="pl-3"> Картин: {{ goods_Sum }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import { AUTH_LOGOUT } from "@/store/actions/auth";

import { bus } from "@/main";
import Vue from "vue";
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbIcon,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbDropdownItem,
  mdbFormInline,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbTooltip,
} from "mdbvue";

import basketJSON from "@/components/basket.json";

export default {
  props: ["orders"],
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbIcon,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
    mdbFormInline,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbTooltip,
  },
  data() {
    return {
      manufacture123435: false,
      today: new Date().toLocaleDateString(),

      statuses: [
        "Печать СПб (ты предложил допы и вставил ССЫЛКУ НА МАКЕТЫ????)",
        "Печать МОСКВА",
        "В производстве",
        "Картина Готова СПБ",
        "Картина Готова МСК",
        "Документы оформлены",
        "Добавлен в план",
        "Картина готова без допов",
        "Прислал на печать",
      ],
      deliveryMethod: [
        "Почта",
        "СДЭК Санкт-Петербург",
        "Самовывоз из офиса",
        "Забери Товар",
        "Достависта Санкт-Петербург",
        "Достависта Москва",
        "самовывоз МСК (партнеры)",
      ],
      Post: [],
      Sdek: [],
      Sdek2: [],
      SamovuvozOffice: [],
      ZaberiMenya: [],
      DostavistaSpb: [],
      DostavistaMsk: [],
      SamovuvozMsk: [],

      orders_Sum: 0,
      goods_Sum: 0,
      datef: new Date(2020, 4, 31),
      datet: new Date(),

      show: false,
      modal_date: false,
      SamovuvozOfficeH: true,
      ZaberiMenyaH: true,
      DostavistaSpbH: true,
      DostavistaMskH: true,
      SamovuvozMskH: true,
      test2: false,
    };
  },
  computed: {
    selectedOrders() {
      let dateF = new Date(this.datef);
      let dateT = new Date(this.datet);

      return this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];

          if (field.fieldId === 88) {
            let dateShipStr = field.value;
            let dateShipDMY = dateShipStr.split(".");
            let dateShipDate = new Date(
              dateShipDMY[2],
              dateShipDMY[1] - 1,
              dateShipDMY[0]
            );
            if (
              dateShipDate.getTime() >= dateF.getTime() &&
              dateShipDate.getTime() <= dateT.getTime() &&
              this.statuses.includes(item.orderStatus.name)
            ) {
              return true;
            }
          }
        }
        return false;
      });
    },
    renderOrders() {
      const orders = this.selectedOrders;
      let goods_Sum = 0;
      for (let i = 0, l = orders.length; i < l; i++) {
        const item = orders[i];
        const basket = [];
        let oplata = "";
        let urgent = "";
        let foto = "";
        let itemQuantity = 0;
        for (let f = 0, g = item.goodsPositions.length; f < g; f += 1) {
          const goodsItem = item.goodsPositions[f];
          const posSum = goodsItem.price * goodsItem.quantity;
          let itemPoint = String(goodsItem.goods.marking);

          let goods_goods = basketJSON.basket.goods;
          for (let h = 0, t = goods_goods.length; h < t; h++) {
            let goodsGOD = goods_goods[h];
            if (goodsGOD.goods_article === itemPoint) {
              itemQuantity = itemQuantity + goodsItem.quantity;
              goods_Sum++;
            }
          }

          if (
            itemPoint.indexOf("Рама") != -1 ||
            itemPoint.indexOf("Гель") != -1 ||
            itemPoint.indexOf("упак") != -1 ||
            itemPoint.indexOf("Упак") != -1 ||
            itemPoint.indexOf("Новогодняя") != -1 ||
            itemPoint.indexOf("УП") != -1 ||
            itemPoint.indexOf("Цветы") != -1 ||
            itemPoint.indexOf("лак") != -1 ||
            itemPoint.indexOf("Поталь") != -1
          ) {
            if (itemPoint === "002 Рама(золото)") itemPoint = "Р(G)";
            if (itemPoint === "Рама") itemPoint = "Р";
            if (itemPoint === "001 Рама(серебро)") itemPoint = "Р($)";
            if (itemPoint === "003 Рама(белая)") itemPoint = "Р(W)";
            if (itemPoint === "Дед Мороз 3 упаковка") itemPoint = "уп дед3";
            if (itemPoint === "УП именная") itemPoint = "уп им";
            if (itemPoint === "УП Бабочки") itemPoint = "уп баб";
            if (itemPoint === "Упаковка Цветы") itemPoint = "уп цветы";
            if (itemPoint === "Новогодняя  Дед Мороз 2") itemPoint = "уп дед2";
            if (itemPoint === "Упаковка в подарок") itemPoint = "уп в подарок";
            if (itemPoint === "Гель") itemPoint = "Г";
            if (itemPoint === "лак" || itemPoint === "лак за 0")
              itemPoint = "Л";

            if (itemPoint === "004 Рама(кремовая)") itemPoint = "Р(Cr)";

            basket.push(`${itemPoint}&nbsp;-&nbsp;${goodsItem.quantity}`);
          }
        }
        if (
          item.internalComments.indexOf("сроч") !== -1 ||
          item.internalComments.indexOf(" СРОЧ ") !== -1 ||
          item.internalComments.indexOf("срочный") !== -1 ||
          item.internalComments.indexOf(" СРОЧНЫЙ ") !== -1
        ) {
          urgent = "!";
        }

        if (
          item.internalComments.indexOf("100%") !== -1 ||
          item.internalComments.indexOf(" Сто ") !== -1 ||
          item.internalComments.indexOf("СТО") !== -1 ||
          item.internalComments.indexOf(" 100 ") !== -1 ||
          item.internalComments.indexOf(" сто ") !== -1
        ) {
          oplata = "%";
        }

        if (
          item.internalComments.indexOf("фот") !== -1 ||
          item.internalComments.indexOf("Фот") !== -1 ||
          item.internalComments.indexOf("ФОТО") !== -1
        ) {
          foto = "Ф";
        }

        for (let j = 0, k = item.customFields.length; j < k; j++) {
          const field = item.customFields[j];
          switch (field.fieldId) {
            case 87: {
              if (field.valueAsText === "Почта") {
                this.Post.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,

                  basket: basket.join(", "),
                });
              }
              if (field.valueAsText === "СДЭК Санкт-Петербург") {
                this.Sdek.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,
                  basket: basket.join(", "),
                });
              }
              if (
                field.valueAsText === "СДЭК Санкт-Петербург" &&
                this.Sdek.length > 60
              ) {
                this.Sdek2.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,
                  basket: basket.join(", "),
                });
              }
              if (field.valueAsText === "Самовывоз из офиса") {
                this.SamovuvozOffice.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,
                  basket: basket.join(", "),
                });
              }
              if (field.valueAsText === "Забери Товар") {
                this.ZaberiMenya.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,
                  basket: basket.join(", "),
                });
              }
              if (field.valueAsText === "Достависта Санкт-Петербург") {
                this.DostavistaSpb.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,
                  basket: basket.join(", "),
                });
              }
              if (field.valueAsText === "Достависта Москва") {
                this.DostavistaMsk.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,
                  basket: basket.join(", "),
                });
              }
              if (field.valueAsText === "самовывоз МСК (партнеры)") {
                this.SamovuvozMsk.push({
                  id: `${urgent} ${oplata}  ${foto} ${item.internalNumber} | ${itemQuantity}`,
                  basket: basket.join(", "),
                });
              }
              break;
            }
          }
        }
      }
      this.goods_Sum = goods_Sum;
      this.orders_Sum = orders.length;
      return orders;
    },
    renderOrdersInStr() {
      const orders = this.renderOrders;
      let Post = this.Post;
      let Sdek = this.Sdek;
      let Sdek2 = this.Sdek2;
      let SamovuvozOffice = this.SamovuvozOffice;
      let ZaberiMenya = this.ZaberiMenya;
      let DostavistaSpb = this.DostavistaSpb;
      let DostavistaMsk = this.DostavistaMsk;
      let SamovuvozMsk = this.SamovuvozMsk;
      if (Sdek2.length) this.test2 = true;
      if (SamovuvozOffice.length) this.SamovuvozOfficeH = false;
      if (ZaberiMenya.length) this.ZaberiMenyaH = false;
      if (DostavistaSpb.length) this.DostavistaSpbH = false;
      if (DostavistaMsk.length) this.DostavistaMskH = false;
      if (SamovuvozMsk.length) this.SamovuvozMskH = false;

      let tableStr = [];

      let znch = [
        Post,
        Sdek,
        Sdek2,
        SamovuvozOffice,
        ZaberiMenya,
        DostavistaSpb,
        DostavistaMsk,
        SamovuvozMsk,
      ];

      let znchL = [
        Post.length,
        Sdek.length,
        Sdek2.length,
        SamovuvozOffice.length,
        ZaberiMenya.length,
        DostavistaSpb.length,
        DostavistaMsk.length,
        SamovuvozMsk.length,
      ];

      for (let i = 0, l = znchL.length; i < l; i++) {
        if (znchL[i] >= znchL[i + 1]) znchL[i + 1] = znchL[i];
      }
      let tableLength = znchL[znchL.length - 1];

      for (let i = 0, l = znch.length; i < l; i++) {
        let ordersList = znch[i];
        for (let j = 0; j < tableLength; j++) {
          if (!ordersList[j]) {
            ordersList.push({
              id: "",
              basket: "",
            });
          }
        }
        znch[i] = ordersList;
      }

      if (tableLength > 60) tableLength = 60;
      for (let i = 0, l = tableLength; i < l; i++) {
        const PostI = Post[i];
        const SdekI = Sdek[i];
        const SdekI2 = Sdek2[i];
        const SamovuvozOfficeI = SamovuvozOffice[i];
        const ZaberiMenyaI = ZaberiMenya[i];
        const DostavistaSpbI = DostavistaSpb[i];
        const DostavistaMskI = DostavistaMsk[i];
        const SamovuvozMskI = SamovuvozMsk[i];

        tableStr.push({
          Post_Id: PostI.id,
          Post_Basket: PostI.basket,

          Sdek_Id: SdekI.id,
          Sdek_Basket: SdekI.basket,

          Sdek2_Id: SdekI2.id,
          Sdek2_Basket: SdekI2.basket,

          SamovuvozOffice_Id: SamovuvozOfficeI.id,
          SamovuvozOffice_Basket: SamovuvozOfficeI.basket,

          ZaberiMenya_Id: ZaberiMenyaI.id,
          ZaberiMenya_Basket: ZaberiMenyaI.basket,

          DostavistaSpb_Id: DostavistaSpbI.id,
          DostavistaSpb_Basket: DostavistaSpbI.basket,

          DostavistaMsk_Id: DostavistaMskI.id,
          DostavistaMsk_Basket: DostavistaMskI.basket,

          SamovuvozMsk_Id: SamovuvozMskI.id,
          SamovuvozMsk_Basket: SamovuvozMskI.basket,
        });
      }
      this.Post = [];
      this.Sdek = [];
      this.Sdek2 = [];
      this.SamovuvozOffice = [];
      this.ZaberiMenya = [];
      this.DostavistaSpb = [];
      this.DostavistaMsk = [];
      this.SamovuvozMsk = [];
      return tableStr;
    },
  },
  methods: {
    openDeliveryTable() {
      bus.$emit("deliveryTableB", true);
    },
    openToolsTable() {
      bus.$emit("toolsTableB", true);
    },
    async logout() {
      await this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push("/");
    },
  },

  watch: {
    datet: function () {
      this.today = new Date(this.datet).toLocaleDateString();
    },
  },
};
</script>