<template>
  <div class="container-fluid min-h px-0 text-roboto">
    <mdb-navbar color="elegant" dark class="navnav w-100">
      <mdb-navbar-toggler>
        <mdb-navbar-nav left>
          <mdb-dropdown tag="li" class="nav-item">
            <mdb-dropdown-toggle tag="a" navLink slot="toggle" waves-fixed
              >Таблицы</mdb-dropdown-toggle
            >
            <mdb-dropdown-menu>
              <mdb-dropdown-item @click="openDeliveryTable"
                >Лист отправки</mdb-dropdown-item
              >
              <mdb-dropdown-item @click="openToolsTable" active
                >План</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>

          <!-- <mdb-nav-item @click="modal_date = true">
            <mdb-icon far icon="calendar-check" size="lg" />
          </mdb-nav-item> -->

          <mdb-nav-item>
            <mdb-icon
              icon="gas-pump"
              size="lg"
              @click.native="modal_date = true"
            />
          </mdb-nav-item>
          <mdb-nav-item>
            <select
              v-model="worker"
              @change="setTitle"
              :disabled="!activeWorkers || !activeWorkers.length"
            >
              <option value="0">Сборщики</option>
              <option
                v-for="item in activeWorkers"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </mdb-nav-item>
        </mdb-navbar-nav>

        <mdb-navbar-nav right>
          <mdb-nav-item @click="logout">Выход</mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>

    <div class="row mx-0 animated fadeInRight">
      <div class="col-12 class text-table"></div>
      <table class="white px-0 text-left w-100">
        <thead>
          <tr class="text-center">
            <th class="ml-1 th_date border-dark border">
              № <mdb-icon icon="sort" class="mx-1" />
            </th>
            <th class="border-dark border">
              <p
                class="my-0"
                style="cursor: pointer"
                @click="modal_date = true"
              >
                {{ today }}
              </p>
              <hr class="my-0" />
              Заказ
            </th>
            <th class="border-dark border">
              <span style="cursor: pointer">Размер больше</span>
            </th>
            <th class="border-dark border">
              <span style="cursor: pointer">Статус заказа</span>
            </th>
            <th class="border-dark border">
              <span style="cursor: pointer">Способ доставки</span>
            </th>
            <th class="border-dark border">
              <span style="cursor: pointer">Дата отправки</span>
            </th>
            <th class="border-dark border">
              <span style="cursor: pointer">Корзина</span>
            </th>
            <th class="border-dark border">
              <span style="cursor: pointer">Примечание</span>
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr class="trT" v-for="(order, index) in normalView" :key="order.id">
            <td class="border-dark border">{{ index + 1 }}</td>
            <td class="border-dark border">{{ order.id }}</td>
            <td class="border-dark border">
              {{ order.size ? order.size : "" }}
            </td>
            <td class="border-dark border">{{ order.status }}</td>
            <td class="border-dark border">{{ order.delivery }}</td>
            <td class="border-dark border">{{ order.date }}</td>
            <td class="border-dark border" v-html="order.basket"></td>
            <td class="border-dark border" v-html="order.comment"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <mdb-modal
      :show="modal_date"
      @close="modal_date = false"
      class="modalmodal"
    >
      <mdb-modal-header>
        <mdb-modal-title>Статусы</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="my-3 text-center">
        <div class="row justify-content-center">
          <select v-model="status" multiple="yes" size="10">
            <option value="0">Все</option>
            <option v-for="item in statuses" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
          <!-- <div>
            <div
              class="custom-control custom-checkbox"
              v-for="item in statuses"
              :key="item"
              :value="item"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                id="defaultUnchecked"
              />
              <label class="custom-control-label" for="defaultUnchecked">
                {{ item }}</label
              >
            </div>
          </div> -->
        </div>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>
<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { bus } from "@/main";
import Vue from "vue";
import {
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbDatePicker,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbIcon,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbDropdownItem,
  mdbInput,
} from "mdbvue";
import basketJSON from "@/components/basket.json";
export default {
  props: ["orders"],
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbDatePicker,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbIcon,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
    mdbInput,
  },
  data() {
    return {
      manufacture123435: false,
      today: new Date().toLocaleDateString(),
      statuses: [
        "Печать СПб (ты предложил допы и вставил ССЫЛКУ НА МАКЕТЫ????)",
        "Печать МОСКВА",
        "В производстве",
        "Картина Готова СПБ",
        "Картина Готова МСК",
        "Документы оформлены",
        "Добавлен в план",
        "Картина готова без допов",
        "Прислал на печать",
      ],
      status: ["0"],
      worker: "0",
      orders_Sum: 0,
      goods_Sum: 0,
      datef: new Date(2020, 4, 31),
      datet: new Date(),
      show: false,
      modal_date: false,
      test2: false,
    };
  },
  computed: {
    selectedOrders() {
      const usedWorker = this.worker;
      const usedStatus = this.status;
      if (!this.orders) {
        return this.orders;
      }
      return this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (
            ((field.fieldId === 559 && field.value === usedWorker) ||
              usedWorker === "0") &&
            (usedStatus.includes(item.orderStatus.name) ||
              (usedStatus[0] === "0" &&
                this.statuses.includes(item.orderStatus.name)))
          ) {
            return true;
          }
        }
        return false;
      });
    },
    normalView() {
      if (!this.orders) {
        return this.orders;
      }
      const orders = this.selectedOrders;
      return orders.map((item) => {
        const res = {
          id: item.internalNumber,
          size: "",
          status: item.orderStatus.name,
          date: "",
          basket: [],
          delivery: "",
          comment: item.internalComments || "",
        };

        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          switch (field.fieldId) {
            case 1303: {
              res.size = field.valueAsText;
              break;
            }
            case 87: {
              res.delivery = field.valueAsText;
              if (res.delivery === "СДЭК Санкт-Петербург") {
                res.delivery = "СДЭК";
              }
              if (res.delivery === "Самовывоз из офиса") {
                res.delivery = "Самовывоз";
              }
              if (res.delivery === "самовывоз МСК (партнеры)") {
                res.delivery = "Самовывоз (МСК)";
              }
              if (res.delivery === "Достависта Санкт-Петербург") {
                res.delivery = "Достависта (СПБ)";
              }
              if (res.delivery === "Достависта Москва") {
                res.delivery = "Достависта (МСК)";
              }
              break;
            }
            case 88: {
              res.date = field.value;
              break;
            }
          }
        }
        const basket = [];
        for (let i = 0, l = item.goodsPositions.length; i < l; i += 1) {
          const goodsItem = item.goodsPositions[i];
          const posSum = goodsItem.price * goodsItem.quantity;
          basket.push(
            `${goodsItem.goods.marking}&nbsp;-&nbsp;${goodsItem.quantity}`
          );
        }
        res.basket = basket.join(", ");
        return res;
      });
    },
    activeWorkers() {
      if (!this.orders) {
        return [];
      }
      const workers = [];
      const has = [];
      for (let i = 0, l = this.orders.length; i < l; i += 1) {
        const fields = this.orders[i].customFields;
        for (let i = 0, l = fields.length; i < l; i += 1) {
          const field = fields[i];
          if (field.fieldId === 559 && has.indexOf(field.value) === -1) {
            workers.push({
              id: field.value,
              name: field.valueAsText,
            });
            has.push(field.value);
          }
        }
      }
      return workers;
    },
  },
  methods: {
    openDeliveryTable() {
      bus.$emit("deliveryTableB", true);
    },
    openToolsTable() {
      bus.$emit("toolsTableB", true);
    },
    setTitle() {
      for (let i = 0, l = this.activeWorkers.length; i < l; i += 1) {
        if (this.activeWorkers[i].id === this.worker) {
          document.title = `Заказы. Сборщик: ${this.activeWorkers[i].name}`;
          return;
        }
      }
      document.title = 'cтудия "Repin"';
    },
    async logout() {
      await this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push("/");
    },
  },
  watch: {
    datet: function () {
      this.today = new Date(this.datet).toLocaleDateString();
    },
  },
};
</script>
<style >
.tbody {
  font-size: 80%;
}
.trT:hover {
  color: rgb(225, 222, 222);
  background-color: rgb(84, 80, 80);
}
</style>