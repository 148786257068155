<template>
  <div class="background-st">
    <!-- <Navbar class="animated fadeInDown" /> -->

    <div v-if="manufacture123435 && orders.length">
      <Delivery v-if="deliveryTable" :orders="orders" />
      <Tools v-if="toolsTable" :orders="orders" />
    </div>
    <div v-else class="text-center" style="margin-top: 30vh">
      <div
        v-if="!Loading"
        class="spinner-border glow-on-hover"
        role="status"
        style="min-height: 10vh"
        @click="getMeOrders"
      >
        <span class="sr-only">Загрузка...</span>
      </div>
      <div v-else>
        <div class="spinner-border btn" role="status" style="min-height: 10vh">
          <span class="sr-only">Загрузка...</span>
        </div>
      </div>

      <!-- <span>Загрузка</span> -->
    </div>
  </div>
</template>

<script>
import {
  mdbTooltip,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbDatePicker,
} from "mdbvue";
import Tools from "@/components/app/Tools";
import Delivery from "@/components/app/Delivery";
import Navbar from "@/components/app/Navbar";
import basketJSON from "@/components/basket.json";
import { bus } from "@/main";

export default {
  components: {
    Navbar,
    Delivery,
    Tools,
  },
  data() {
    return {
      manufacture123435: false,
      today: new Date().toLocaleDateString(),
      orders: [],
      deliveryTable: true,
      toolsTable: false,
      tableSwitch: false,
      Loading: false,
    };
  },
  created() {
    bus.$on("deliveryTableB", (data) => {
      if (this.deliveryTable === false) {
        this.deliveryTable = data;
        this.toolsTable = false;
      }
    });
    bus.$on("toolsTableB", (data) => {
      if (this.toolsTable === false) {
        this.toolsTable = data;
        this.deliveryTable = false;
      }
    });
  },
  methods: {
    async getMeOrders() {
      this.Loading = true;
      const usedWorker = await localStorage.getItem("user-status");
      if (usedWorker === "23") {
        // this.orders = await request("http://45.84.227.225/api/orders2");
        this.manufacture123435 = true;
        var today = new Date(new Date().getTime() + 172800000);
        var dateFrom2 = new Date(2020, 5, 1).toISOString().substr(0, 10);
        var dateTill = today.toISOString().substr(0, 10);
        var statuses2 = [
          { name: "Передан дизайнеру" },
          { name: "Правки" },
          { name: "Черновик" },
          { name: "Согласование" },
          { name: "Ждём 2 предоплату" },
          {
            name: "Печать СПб (ты предложил допы и вставил ССЫЛКУ НА МАКЕТЫ????)",
          },
          { name: "Печать МОСКВА" },
          { name: "В производстве" },
          { name: "Картина Готова СПБ" },
          { name: "Картина Готова МСК" },
          { name: "Документы оформлены" },
          { name: "Добавлен в план" },
          { name: "Картина готова без допов" },
          { name: "Прислал на печать" },
        ];
        let orders_all = await request(
          `http://45.84.227.225/api/orders_all`,
          "POST",
          {
            dateFrom: dateFrom2,
            dateTill,
            statuses: statuses2,
          }
        );
        if (orders_all === "Ошибка...") {
          alert(
            "Ошибка...в бс...Рекомендуемое время повторного запроса: 1 минута." // ошибка в бс (другой пользователь)
          );
        } else if (orders_all === "er") {
          alert(
            "Повторите запрос через некоторое время" // наша ошибка
          );
        } else this.orders = orders_all;
        this.Loading = false;
      }
    },
  },
};

async function request(url, method = "GET", data = null) {
  try {
    const headers = {};
    let body;

    if (data) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    return await response.json();
  } catch (e) {
    console.warn("Error:", e.message);
  }
}
</script>


<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.text-table {
  font-size: smaller;
}

.loaderOrder {
  min-height: 80vh;
}

.glow-on-hover {
  width: 220px;
  height: 220px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  /* border-radius: 10px; */
  border-radius: 50%;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 50%;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 50%;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>